import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import Helmet from "react-helmet"
import logo from "../images/logo.png"
import {
  GlobalStyle,
  Image,
  ImageContainer,
  FooterText,
  Header,
  Seperator,
  Container,
  LoadingContainer,
  HubspotForm
} from '../styles/index.styles'


const Index = () => {
  const [loading, setLoading] = useState(true)

  const loadFormScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = "//js.hsforms.net/forms/v2.js"
      script.addEventListener('load', () => {
        resolve();
      })
      script.addEventListener('error', e => {
        reject(e);
      })      
      document.head.appendChild(script)
    })
  }

  const loadingSpinner = () =>  <LoadingContainer><i class="fas fa-spinner fa-pulse" /></LoadingContainer>

  const createForm = () => {
    return new Promise((resolve, reject) => {
      const element = document.querySelector('#hubspotForm')
      const script = document.createElement('script')
      script.src = "/hubspot.js"
      script.defer = true
      script.addEventListener('load', () => {
        resolve();
      })
      script.addEventListener('error', e => {
        reject(e);
      })
      element.appendChild(script)
    })
  }

  const checkForElement = (element, input) => {
    return new Promise((resolve) => {
      setInterval(() => {
        if (element.length) {
          clearInterval(checkForElement)
          resolve({ el: element[0], input });
        }
      }, 100)
    })
  }

  const deleteLabel = el =>
    el.querySelector('label').style.display = "none";

  const addPlaceholderAttribute = (el, placeholder) =>
    el.querySelector('.hs-input').placeholder = placeholder

  const modifyForm = () => {
    const rootElement = document.querySelector('.hbspt-form')
    const inputs = [
      { class: 'hs_firstname', placeholder: "Enter First Name"}, 
      { class: 'hs_lastname', placeholder: "Enter Last Name" },
      { class: 'hs_email', placeholder: "Enter Email"}
    ]

    inputs.forEach(input => {
      const element = rootElement.getElementsByClassName(input.class)
      checkForElement(element, input).then(({ el, input }) => {
        deleteLabel(el)
        addPlaceholderAttribute(el, input.placeholder)
      })
    })
  }

  useEffect(() => {
    loadFormScript()
      .then(() => {
        createForm()
          .then(() => {
            modifyForm()
            setLoading(false)
          })
      })
      .catch(err => console.log(err))
  }, [])
  
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <title>StudentRoomStay | Welcome</title>
        <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap" rel="stylesheet" />
        <script src="https://kit.fontawesome.com/23f601ce56.js" crossorigin="anonymous" />
      </Helmet>
      <Layout>
        <Container>
          <ImageContainer>
            <Image src={logo} alt="StudentRoomStay Logo" />
          </ImageContainer>
          <Header fs={1.4} weight={500} steel>
            The best student accommodations are waiting – sign up below!
          </Header>
          <Seperator />
          <Header mt={1} mb={1.5} fs={1.3}>Get notified when we open our doors</Header>
          { loading && loadingSpinner() }
          <HubspotForm id="hubspotForm" display={loading ? 'none' : 'flex'} />
          <FooterText>Part of the <a href="https://mystayinternational.com" target="_blank">MyStay International Group</a></FooterText>
        </Container>
      </Layout>
    </>
  )
}

export default Index;
