import React from "react";
import styled from "styled-components"
import background from "../images/background.jpg"

const Container = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex: 1 1 auto;
    background: url(${background});
    background-position: center;
    background-size: cover;
  }
  * {
    box-sizing: border-box;
  }
`

const Form = styled.div`
  text-align: center;
  background: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 6px 18px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2);
  @media (min-width: 1024px) {
    width: 675px;
  }
`

const Layout = ({ children }) => (
  <Container>
    <Form>
      {children}
    </Form>
  </Container>
)

export default Layout;
