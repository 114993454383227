import styled, { css, createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'lato'
  }
`

const sharedHeaderStyles = css`
  color: #315AB7;
`

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 2em;
  margin: 1em
`

const HubspotForm = styled.div`
  display: ${({ display }) => display && display};
  justify-content: center;
  width: calc(100% + 15px);
  * {
    box-sizing: border-box
  }
  .hs-richtext p {
    text-align: left;
    color: #91AAB7;
    font-size: 0.9em;
    line-height: 1.4em;
  }
  .hbspt-form, iframe {
    width: 100%;
  }
  .hs-input:not([type='checkbox']) {
    border: 1px solid #D2DADF;
    border-radius: 4px;
    margin-bottom: 0.3rem;
    padding: 0.8rem 1.3rem;
    width: 100% !important;
    &::placeholder {
    color: #D2DADF;
    }
  }
  .form-columns-2 {
    margin-bottom: 0.25em;
  }
  .form-columns-1,
  .form-columns-2,
  .form-columns-3 {
    max-width: 100%;
  }
  .hs-error-msgs {
    color: red;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    margin-top: 0.75em;
    & li {
      display: block;
      text-align: center;
    }
  }
  .inputs-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    margin-top: 0.75em;
  }

  .legal-consent-container {
    & input {
      appearance: checkbox;
      width: 1em !important;
      height: 1em;
    }
    & label {
      color: #91AAB7;
      font-weight: bold;
    }
  }
  .hs-button {
    border-radius: 4px;
    padding: 0.7rem 1.2rem;
    background: #DE393B;
    color: white;
    display: block;
    margin: auto;
    margin-top: 1rem;
  }
`

const Image = styled.img`
  margin: auto;
  margin-bottom: 1.5rem;
  height: 100%;
  @media (min-width: 450px) {
    width: 270px;
  }
  @media (max-width: 450px) {
    width: 100%; 
  }
`

const ImageContainer = styled.div``


const Button = styled.button`
  border-radius: 4px;
  padding: 0.7rem 1.2rem;
  background: #DE393B;
  color: white;
  width: 30;
  display: block;
  margin: auto;
  margin-top: 1rem;
`

const Input = styled.input`
  border: 1px solid #D2DADF;
  border-radius: 4px;
  margin-bottom: ${props => props.mb || 0.3}rem;
  padding: 0.8rem 1.3rem;
  &::placeholder {
   color: #D2DADF;
  }
`

const Checkbox = styled.input.attrs({
  type: "checkbox"
})`
  margin-right: 10px;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 400px) {
    width: 40px;
    height: 40px;
  }
`

const CheckboxLabel = styled.label`
  text-align: left;
  display: flex;
  margin-bottom: ${props => `${props.mb}rem`};
`

const FooterText = styled.p`
  text-align: center;
  display: block;
  margin-top: 1.5em;
  margin-bottom: 0em;
  color: #AFBBC4;
  font-weight: bold;
  & a {
    color: #074165;
  }
`

const CheckboxText = styled.span`
  color: #AFBBC4;
`

const Header = styled.h1`
  ${sharedHeaderStyles}
  margin-top: ${props => props.mt || 0}rem;
  margin-bottom: ${props => props.mb || 1}rem;
  font-size: ${props => props.fs || 1.8}rem;
  color: ${props => props.steel && '#7E95A1'};
  font-weight: ${props => props.weight};
`

const Seperator = styled.hr`
  width: 100%;
  border-top: 1px solid #dcdcdc;
`

const Container = styled.div`
  max-width: 85%;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  & a {
    color: #6ABCFF;
    text-decoration: none;
  }
  & form {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 450px) {
    padding: 2rem;
  }
`

export {
  GlobalStyle,
  Image,
  ImageContainer,
  Button,
  Input,
  Checkbox,
  CheckboxText,
  CheckboxLabel,
  FooterText,
  Header,
  Seperator,
  Container,
  LoadingContainer,
  HubspotForm
}
